/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import NoSSR from "react-no-ssr"
import BackgroundSlider from "react-background-slider"
import PageWrapper from "../../layouts/PageWrapper"
import HomeLogo from "../../components/HomeLogo"
import InfoImg from "./infobg.jpg"

class CorePage extends Component {
  render() {
    return (
      <div>
        <PageWrapper>
          <div>
            <NoSSR>
              <BackgroundSlider
                images={[InfoImg]}
                duration={500000}
                transition={1}
              />
            </NoSSR>
            <Helmet>
              <title>Core Businesses - E.A. Technique</title>
            </Helmet>
            <section className="hero is-small">
              <div className="hero-body">
                <div className="container">
                  <div className="columns">
                    <div className="column">
                      <div className="section">
                        <HomeLogo />
                        <h1 className="title" id="title-line">
                          Corporate Info
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section is-paddingless" id="core_page">
              <div className="container">
                <div className="columns">
                  <div className="column" id="corporateinfo-vision-column">
                    {/* <div className="box"
                        style={{
                          opacity: 0.9}}> */}
                    <h3 className="title is-primary is-3">
                      Marine Transportation and Offshore Storage of Oil & Gas
                    </h3>
                    <p>
                      Our product tankers are used to transport refined
                      petroleum products from oil refineries to end users or to
                      another refinery for further processing known as Clean
                      Petroleum Products ("CPP") e.g. kerosene (jet fuel) diesel
                      and petrol (RON95 & RON97). FSU/FSO are typically used to
                      support production platforms as an offshore Oil & Gas
                      storage facility at brown fields; and Liquid Petroleum Gas
                      ("LPG") tankers are used to transport liquefied gasses
                      including propane, butane and other gases such as
                      propylene and butylene, albeit in smaller concentrations.
                      These gases are required to be transported under high
                      pressure and/or low temperatures to maintain them in a
                      liquid state We also operate Offshore Supply Vessels
                      ("OSV") namely fast crew boats which are primarily used to
                      transport personnel/light cargoes between shore and
                      platform, platform and platform and other offshore
                      facilities
                    </p>
                  </div>
                  {/* </div> */}
                  <div className="column is-1" />

                  <div className="column">
                    <div className="columns" id="core-bg">
                      <div className="column " id="corporateinfo-vision-column">
                        <h1 id="corporateinfo-vision-title">Vision</h1>
                      </div>
                      <div id="testing" />

                      <div className="column" id="corporateinfo-vision-column">
                        To be the locally preferred service provider of Marine
                        Services With a Global Vision
                      </div>

                      <div className="column" id="corporateinfo-vision-column">
                        <h1 id="corporateinfo-vision-title">Mission</h1>
                      </div>
                      <div id="testing" />

                      <div className="column" id="corporateinfo-vision-column">
                        A local shipping company focuses and examplifies
                        attributes of: Quality + Security Culture + Safety
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </PageWrapper>
      </div>
    )
  }
}

CorePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default CorePage
